import React from 'react';
import axios from 'axios';
import { doc, setDoc } from 'firebase/firestore';
import { auth, logOut, db } from './firebase'; // Ensure all necessary functions are imported correctly
import Postlist from './postlist';
import Nobreach from './Nobreach';
import Loader from './Loader';
import Searchbar from './searchbar';
import Result from './result';
import Stat from './Stat';
import BuyMeACoffee from './BuyMeACoffee';
import Login from './Login';

class App extends React.Component {
  state = { result: [], noresult: '', load: false, update: 0, user: null };

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user }, () => {
          // Trigger a breach search with the user's email immediately after login
          this.onSubmitform(user.email);
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user !== this.state.user && !this.state.user) {
      // Reset state when user logs out
      this.setState({ result: [], noresult: '', load: false, update: 0 });
    }
  }

  onSubmitform = async (term) => {
    if (!this.state.user) {
      alert('Please login to continue.');
      return;
    }

    this.setState({ load: true });
    try {
      const response = await axios.get(
        `https://us-central1-ito-28e6d.cloudfunctions.net/cors?url=https://haveibeenpwned.com/api/v3/breachedaccount/${term}?truncateResponse=false`,
        {
          headers: {
            'hibp-api-key': process.env.REACT_APP_HIBP_API_KEY,
            'User-Agent': 'Secureito.com',
            'Origin': window.location.origin
          }
        }
      );

      const resultLength = response.data ? response.data.length : 0;

      console.log("Preparing to update Firestore with result length:", resultLength);
      if (term==this.state.user.email){
      await setDoc(doc(db, "users", this.state.user.uid), {
        userEmail: this.state.user.email,
        resultLength: resultLength,
        lastChecked: new Date()
      }, { merge: true });
}
      console.log("Firestore updated successfully");

      this.setState({ load: false, result: response.data, noresult: 0, update: 1 });
    } catch (e) {
      if (term==this.state.user.email){
        await setDoc(doc(db, "users", this.state.user.uid), {
          userEmail: this.state.user.email,
          resultLength: 0,
          lastChecked: new Date()
        }, { merge: true });
  }
      console.error("Failed to fetch data or update Firestore:", e);

      this.setState({ load: false, update: 1, result: [], noresult: 1 });
    }
  }

  handleLogout = async () => {
    await logOut();
    this.setState({ user: null, result: [], noresult: '', load: false, update: 0 });
  }

  render() {
    return (
      <div>
        {!this.state.user ? (
          <Login />
        ) : (
          <div className="ui float container" style={{ 'backgroundColor': 'F0FFF0' }}>
            <h2>Hi, {this.state.user.displayName}!</h2>
            <button
              style={{
                'backgroundColor': '#11aba0',
                'marginTop': '10px',
                'boxShadow': '0px 0px 6px 0.3px rgba(166,166,166,1)',
                'borderRadius': '8px',
                'border': 'none',
                'color': 'white',
                'fontSize': '20px'
              }}
              onClick={this.handleLogout}
            >
              Log out
            </button>

            <div>
              <Searchbar onSubmit={this.onSubmitform} userEmail={this.state.user.email} />
              <div>
                {this.state.load ? <Loader /> : <Result len={this.state.result} />}
              </div>
            </div>
            <br />
            <div style={{ 'Position': 'absolute' }}>
              <br />
              <BuyMeACoffee />
              {this.state.noresult === 1 ? <Nobreach update={this.state.update} /> : <Postlist results={this.state.result} update={this.state.update} />}
            </div>
            <br />
            <Stat />
          </div>
        )}
      </div>
    );
  }
}

export default App;
