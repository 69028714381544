import React from 'react';
import Adcomponent from './Adcomponent';

const Update=()=>{
return(<div>
	<div className="ui container" style={{'padding':'20px','boxShadow':'0px 0px 8px 1px rgba(166,166,166,1)','borderRadius': '10px','margin':'10px','backgroundColor':'#6696C8','color':'White', 'fontSize':'20px'}}> 
	<div style={{'marginBottom':'8px'}} >If one of your accounts is compromised, others could be at risk as well. We strongly recommend securing all your accounts. Utilize our Directory feature for enhanced protection and peace of mind.</div>
	
	<a href="/directory.html" style={{'backgroundColor':'#32CD32','backgroundShadow':'0px 0px 8px 1px rgba(166,166,166,1)'}} rel="noopener noreferrer" target="_blank" class="ui left floated primary button">
				Visit Our Directory
          <i className="right chevron icon"></i>
        </a>
	</div>


	<br></br>
	<Adcomponent />
	</div>
);
};

export default Update;