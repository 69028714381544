// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtACIxO8OleH9qT-I6CK2G07yC2n5ztp8",
  authDomain: "ito-28e6d.firebaseapp.com",
  databaseURL: "https://ito-28e6d-default-rtdb.firebaseio.com",
  projectId: "ito-28e6d",
  storageBucket: "ito-28e6d.appspot.com",
  messagingSenderId: "971258876782",
  appId: "1:971258876782:web:70e0c52efb681b30aee77f",
  measurementId: "G-KY5QLEG0WS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Function to sign in with Google
export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

// Function to sign out
export const logOut = () => {
  signOut(auth)
    .then(() => {
      console.log("User signed out successfully");
    })
    .catch((error) => {
      console.error("Error signing out: ", error);
    });
};

const db = getFirestore(app);
// Export auth for use in other components
export { auth,provider,db };
