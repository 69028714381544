import React from 'react';
import { signInWithGoogle } from './firebase';
import './Login.css';
const size=window.innerWidth >800? '60px':'40px';

const fsize=window.innerWidth >800? '20px':'12px';

const align=window.innerWidth >800? '-75%':'-50%';
const Login = () => {
  return (
    <div className="login-container">
      <div className="searchbar-wrapper">
    <div className="ui fluid action input" style={{ 'marginTop': '15px', 'borderRadius': '15px', 'height': `${size}`, 'boxShadow': '0px 0px 4px 0.3px rgba(166,166,166,1)' }}>
  <input    type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" style={{ 'fontSize': `${fsize}`, 'backgroundColor': 'white', 'color': 'black' }} placeholder="Enter your email here" />
  <button style={{ 'backgroundColor': '#11aba0', 'fontSize': `${fsize}`, 'color': 'white' }} className="ui button">Search</button>
</div>
<div className="ui big label" style={{ 'marginTop': '15px', "marginLeft": `${align}`,'fontSize': `${fsize}`, 'boxShadow': '0px 0px 6px 0.3px rgba(166,166,166,1)' }}>
  Found 0 Breaches
</div>


        <div className="lock-overlay">
          <img src="https://img.icons8.com/?size=100&id=40348&format=png&color=000000" alt="Lock" className="lock-icon" />

        </div>
      </div>          <div className="signin-messages">
    <h2> Sign-in to unlock</h2>
    
    <ul className="features-list">
    <li>
        <img src="https://img.icons8.com/?size=50&id=111487&format=png&color=000000" className="icon"/>
        <div>
            <strong className="feature-title">Search for email data breaches</strong>
            <span className="feature-detail"> Unlimited email searchs for data breaches.</span>
        </div>
    </li>
    <li>
        <img src="https://img.icons8.com/?size=50&id=13717&format=png&color=000000" className="icon" />
        <div>
            <strong className="feature-title">Get automatic alerts</strong>
            <span className="feature-detail">Receive notifications for new breaches.</span>
        </div>
    </li>
    <li>
    <img src="https://img.icons8.com/?size=40&id=85841&format=png&color=000000" className="icon" />
    <div>
            <strong className="feature-title">It's completely FREE</strong>
            <span className="feature-detail">No charges, completely FREE. Just security.</span>
        </div>
    </li>
</ul>

</div>

      <button className="gsi-material-button" onClick={signInWithGoogle}>
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">Sign in with Google </span>
          <span style={{ display: 'none' }}>Sign in with Google </span>

        </div>
      </button>
      <p className="legal-message">By signing in, you consent to receive alerts about potential security breaches and occasional updates from us.</p>

    </div>
  );
};

export default Login;
