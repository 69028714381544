import React from 'react';
import Update from './Update';

const Nobreach=({update})=>{
	return (
    <div>
<div >
   <div data-tooltip="A data breach is a security incident in which confidential data is stolen" data-position="top center" style={{'fontSize':'30px','color':'red','fontWeight':'bold','textAlign':'center'}}>Breach Accounts</div>
    <hr style={{'align':'center','width':'8%','marginTop':'20px','marginBottom':'15px',  'border': '1px solid red','backgroundColor':'red','color':'red'}}></hr>
    <div className="ui container" style={{'padding':'20px','margin':'auto','float':'center','width':'100%','backgroundColor':'#e3fbe3','boxShadow':'0px 0px 8px 1px rgba(135,127,127,1)','borderRadius':'10px'}}>
<div style={{'fontSize':'18px'}} >
  
Great news! No leaks were found in the database. We’ve searched through over 4 billion records, and your vigilance has paid off. However, a breach is always a possibility. Stay informed. Stay secure."
<br></br>
          <div >
</div>
</div>
  </div>
  </div>
  {update ===1? <Update />: null}
  </div>);











  

};

export default Nobreach;

